// 获取 meta 标签
const metaTag = document.querySelector('meta[name="theme-color"]');

// 注册暗黑模式触发器回调函数
volantis.dark.push(updateMetaThemeColor, "updateMeta");

// 初始化时更新meta标签
updateMetaThemeColor();

// 更新meta标签的内容
function updateMetaThemeColor() {
    const currentMode = volantis.dark.mode;

    // 设置不同模式下的颜色
    if (currentMode === 'dark') {
        metaTag.content = '#262626'; // 暗黑模式下的颜色
    } else {
        metaTag.content = '#fff'; // 一般模式下的颜色
    }
}
